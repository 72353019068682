import { Box } from "@material-ui/core";
import React from "react";
import CookieConsent from "react-cookie-consent";

const CookieConsentBanner = () => {
  return (
    <Box className="buttonAcc">
      <CookieConsent
        location="bottom"
        buttonText="Accept All Cookies"
        containerClasses="alert alert-warning col-lg-12"
        contentClasses="text-capitalize"
        cookieName="Arbitrage_Bot"
        style={{ zIndex: "9999" }}
        buttonStyle={{
          borderRadius: "5px",
          color: "#ffffff",
          fontSize: "18px",
          fontFamily: "Poppins",
          fontWeight: 600,
          padding: "10px 39px",
          marginRight: "10px",
          backgroundColor: "#FF6905",
          "&:hover": {
            color: "#ffffff",
            background: "transparent",
            boxShadow:
              "0 1px 0 0 #FF6905, 0 -1px 0 0 #FF6905, 1px 0 0 0 #FF6905, -1px 0 0 0 #FF6905, 1px -1px 0 0 #FF6905, -1px 1px 0 0 #FF6905, 1px 1px 0 0 #FF6905, -1px -1px 0 0 #FF6905",
            backgroundColor: "transparent",
          },
          whiteSpace: "pre",
          minWidth: "100px",
        }}
      >
        By clicking "Accept All Cookies", you agree to the store the cookies on your device for enhancing site navigation, analyzing site usage, and assisting in our marketing efforts.
      </CookieConsent>
    </Box>
  );
};

export default CookieConsentBanner;
