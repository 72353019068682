import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  makeStyles,
  IconButton,
  Typography,
  SvgIcon,
  Toolbar,
  AppBar,
  Hidden,
  Avatar,
  Grid,
  Box,
  Badge,
  Button,
  DialogActions,
  DialogContent,
  Dialog,
} from "@material-ui/core";
import { Menu as MenuIcon } from "react-feather";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "src/context/Auth";
import Logo from "src/component/Logo";
import { BiBell } from "react-icons/bi";
import SettingsContext from "src/context/SettingsContext";
import { FiSun } from "react-icons/fi";
import { FaRegMoon } from "react-icons/fa";
import { TbLogout } from "react-icons/tb";
const useStyles = makeStyles((theme) => ({
  toolbar: {
    background: "transparent !important",
  },
  toolbarLight: {
    background: "#FFF !important",
  },

  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  mainheader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",

    "& svg": {
      color: theme.palette.text.primary,
    },
    "& .leftBox": {
      width: "306px",
      [theme.breakpoints.down("md")]: {
        width: "200px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "150px",
      },
      "& img": {
        width: "150px",
        [theme.breakpoints.down("xs")]: {
          width: "104px",
          paddingLeft: "0 !important",
        },
      },
    },
    "& .filtersButton": {
      "& .filterIcon": {
        "& button": {
          background: theme.palette.background.profile,
          width: "37px",
          height: "37px",
          borderRadius: "10px",
          padding: "0px",
          "& svg": {
            position: "absolute",
            color: theme.palette.primary.main,
            zIndex: 3,
          },
        },
      },
    },
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const history = useHistory();
  const [isLogout, setIsLogout] = useState(false);
  const themeSeeting = useContext(SettingsContext);
  const changeTheme = (type) => {
    themeSeeting.saveSettings({
      theme: type,
    });
  };
  const [profileData, setProfileData] = useState({});

  useEffect(() => {
    if (auth?.userData) {
      setProfileData({
        profilePic: auth?.userData?.profilePic,
      });
    } // eslint-disable-next-line
  }, [auth?.userData]);

  return (
    <AppBar
      elevation={0}
      className={clsx(classes.root, className)}
      color="inherit"
      {...rest}
      style={
        themeSeeting.settings.theme === "DARK"
          ? { backgroundColor: "#26262C" }
          : { backgroundColor: "#FFF" }
      }
    >
      <Toolbar
        className={
          themeSeeting.settings.theme === "DARK"
            ? `${classes.toolbar}`
            : `${classes.toolbarLight}`
        }
      >
        <Hidden lgUp>
          <IconButton
            color="#00e0b0"
            onClick={onMobileNavOpen}
            style={{ padding: "0px" }}
          >
            <SvgIcon>
              <MenuIcon style={{ color: "#ff6600", fontSize: "25px" }} />
            </SvgIcon>
          </IconButton>
        </Hidden>
        &nbsp; &nbsp;
        <Box className={classes.mainheader}>
          <Grid container alignItems="center">
            <Grid item lg={3} md={3} sm={4} xs={6}>
              <Box className="leftBox">
                <Link to={window.location.pathname == "/" ? "" : "/dashboard"}>
                  <Logo width="125" />
                </Link>
              </Box>
            </Grid>
            <Hidden xsDown>
              <Grid item lg={4} md={6} sm={5}></Grid>
            </Hidden>

            <Grid item lg={5} md={3} sm={3} xs={6}>
              <Box className="displayEnd">
                <Box className="filtersButton displayStart">
                  {themeSeeting.settings.theme === "DARK" ? (
                    <Box className="filterIcon" ml={1}>
                      <IconButton
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          changeTheme("LIGHT");
                        }}
                      >
                        <FiSun />
                      </IconButton>
                    </Box>
                  ) : (
                    <Box className="filterIcon" ml={1}>
                      {" "}
                      <IconButton
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          changeTheme("DARK");
                        }}
                      >
                        <FaRegMoon />
                      </IconButton>
                    </Box>
                  )}
                  <Box className="filterIcon" ml={1}>
                    <IconButton onClick={() => history.push("/notification")}>
                      <BiBell />
                    </IconButton>
                  </Box>
                  <Box className="filterIcon" ml={1}>
                    <IconButton onClick={() => setIsLogout(true)}>
                      <TbLogout />
                    </IconButton>
                  </Box>
                  {isLogout && (
                    <Dialog
                      maxWidth="sm"
                      fullWidth
                      className={classes.dailogOpen}
                      open={isLogout}
                      // TransitionComponent={Transition}
                      // keepMounted
                      onClose={() => setIsLogout(false)}
                    >
                      <DialogContent>
                        <Box className={classes.dialougTitle} align="center">
                          <Typography variant="h4" color="primary">
                            Logout
                          </Typography>
                          <Typography
                            variant="h6"
                            color="primary"
                            style={{ marginTop: "8px" }}
                          >
                            Are you sure want to logout?
                          </Typography>
                        </Box>
                      </DialogContent>
                      <DialogActions
                        style={{
                          alignContent: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Box mt={2} mb={2}>
                          <Button
                            className="modelbtn"
                            color="primary"
                            variant="contained"
                            style={{ padding: "8px 21px" }}
                            onClick={() => setIsLogout(false)}
                          >
                            No
                          </Button>
                          &nbsp; &nbsp;
                          <Button
                            className="modelbtnyes"
                            style={{
                              padding: "8px 21px",
                            }}
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                              window.localStorage.removeItem("token");
                              auth.userLogIn(false, null);
                              history.push("/");
                            }}
                          >
                            Yes
                          </Button>
                        </Box>
                      </DialogActions>
                    </Dialog>
                  )}
                  {/* <Box
                    ml={2}
                    onClick={() => history.push("/profile")}
                    style={{ cursor: "pointer" }}
                  >
                    <Avatar
                      src={
                        profileData.profilePic
                          ? profileData.profilePic
                          : "images/editprofile.png"
                      }
                    />
                  </Box> */}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarData() {
  const classes = useStyles();
  const auth = useContext(AuthContext);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <Hidden xsDown>
          <Box>
            <Typography variant="h5">NFT Marketplace</Typography>
            <Typography variant="body1" style={{ color: "#ffffff9c" }}>
              example@gmail.com
            </Typography>
          </Box>
        </Hidden>
        &nbsp; &nbsp;
        <Avatar
          src={
            auth?.userData?.profilePic
              ? `${auth?.userData?.profilePic}`
              : "https://picsum.photos/533/357"
          }
          className={classes.avatar}
          // onClick={() => history.push("/admin-profile")}
        />
      </Box>
    </>
  );
}
